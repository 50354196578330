<template lang="pug">
  .manager-identities-show
    b-row.mb-5
      b-col
        identities-panel(ref='identitiesPanel')
    b-row.mb-5
      b-col
        router-view(@identity-changed='reloadIdentitiesPanel')
</template>

<script>
import IdentitiesPanel from '@views/container/manager/identities/Panel'

export default {
  name: 'manager-identities-show',
  components: {
    IdentitiesPanel,
  },
  methods: {
    reloadIdentitiesPanel() {
      this.$refs.identitiesPanel.panelReload()
    },
  },
}
</script>
